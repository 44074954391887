<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h2>News Feed</h2>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="javascript:void(0)"> {{ blogTitle }}</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-8">
              <div class="saasio-blog-details-content">
                <div class="blog-details-img">
                  <img src="assets/images/blogs/crm3.PNG" alt="C'est quoi un logiciel CRM" />
                </div>
                <div class="blog-details-text dia-headline">
                  <h1>{{ blogTitle }}</h1>
                  <article>
                    Un logiciel CRM peut vous être utile de plusieurs façons, notamment :
                  </article>
                  <h3>Gestion des contacts : </h3>
                  <article>
                    Vous pouvez stocker toutes les informations importantes sur vos clients et prospects
                     dans un endroit centralisé, y compris leur nom, leur adresse e-mail, leur numéro de téléphone,
                      leur historique d'achat et de communication, etc. Cela peut vous aider à mieux comprendre vos clients
                       et à personnaliser vos interactions avec eux.
                  </article>
                  <h3>Gestion des ventes : </h3>
                  <article>
                    Un logiciel CRM peut vous aider à suivre l'avancement de vos ventes, à gérer les opportunités commerciales
                     et à prévoir les ventes futures. Vous pouvez suivre les interactions avec les prospects, planifier des
                      rappels de suivi et attribuer des tâches à votre équipe de vente.
                  </article>
                  <h3>Gestion du marketing : </h3>
                  <article>
                    Vous pouvez créer et exécuter des campagnes de marketing ciblées,
                     suivre les résultats de vos campagnes et analyser les données pour améliorer votre stratégie de marketing.
                  </article>
                  <h3>Gestion du service clientèle : </h3>
                  <article>
                    Vous pouvez suivre les demandes d'assistance et les réclamations de vos clients, les classer par ordre de
                     priorité et attribuer des tâches à votre équipe d'assistance. Vous pouvez également mesurer les temps 
                     de réponse et la satisfaction des clients pour améliorer vos services.
                  </article>
                  <article>
                    En résumé, un logiciel CRM peut vous aider à mieux comprendre vos clients, 
                    à améliorer vos ventes et votre marketing, et à fournir un meilleur service clientèle. 
                    Cela peut vous aider à renforcer la relation avec vos clients et à améliorer la rentabilité de votre entreprise.
                  </article>
                </div>

                <ShareContent />

              </div>
            </div>
            
            <RightSideBar />

          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
import ShareContent from '../components/ShareContent.vue';
import RightSideBar from '../components/RightSideBar.vue';
export default {
    name : 'BlogDetail3',
    components : {
      ShareContent,
      RightSideBar
    },
    data() {
      return {
        //blogDetail : require('../assets/blogs/crm9.PNG'),
        blogTitle : "Comment il peut me servire ce logiciel CRM ?"
      }
    }
};
</script>
